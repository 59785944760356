
import { progressStore } from '@/core/celery'
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    callback: {
      type: Function as PropType<() => void>,
      required: true
    },
    progressId: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const progressPercent = computed(() => {
      return props.progressId === progressStore.progresId ? progressStore.percent : 0
    })

    return () => (
      <button
        class={['progress-button', { progress: props.isLoading }]}
        type="button"
        style={{ backgroundPosition: `calc(100% - ${progressPercent.value}%)` }}
        onClick={props.callback}
      >
        <span>{props.text}</span>
        {props.isLoading && <span>{`${progressPercent.value}%`}</span>}
      </button>
    )
  }
})

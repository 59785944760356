
import { DefaultInput, ModalForm } from 'apptimizm-ui'
import { defineComponent, PropType, ref } from 'vue'
import { RmmSettings } from '../types'

export default defineComponent({
  props: {
    rmmSettings: {
      type: Object as PropType<RmmSettings>,
      required: true
    },
    closeModal: {
      type: Function as PropType<() => void>,
      required: true
    },
    confirmCallback: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup (props) {
    const password = ref('')

    function setPassword (newVal: string) {
      password.value = newVal
    }

    const errors = ref<string[]>([])

    function checkPassword () {
      if (password.value && password.value === props.rmmSettings.password) {
        props.confirmCallback()
        props.closeModal()
        return
      }

      errors.value = ['Пароль указан неверно']
    }

    return () => (
      <div class="check-password-modal">
        <ModalForm
          header="Введите пароль"
          cancel={props.closeModal}
          confirm={() => {}}
          slots={{
            default: () => (<DefaultInput
              class="mt-3"
              modelValue={password.value}
              onValueChange={setPassword}
              placeholder="Введите пароль"
              errors={errors.value}
            />),
            buttons: () => (<button class="primary-button mt-3" onClick={checkPassword}>Войти</button>)
          }}
        />
      </div>
    )
  }
})

import { defineComponent, onMounted, Ref, ref } from 'vue'

export const CanvasSchedule = defineComponent({
  props: {
    procent: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      default: '#8940FF'
    },
    text: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const canvasElement = ref() as Ref<HTMLCanvasElement>
    const drawSchedule = (): void => {
      const ctx: CanvasRenderingContext2D|null = canvasElement.value.getContext('2d')
      if (ctx) {
        canvasElement.value.height = props.height * 2
        canvasElement.value.width = props.width * 2
        const radian = 2 * Math.PI * props.procent / 100
        const lineWidth = props.width / 3.75
        ctx.beginPath()

        ctx.beginPath()
        ctx.lineWidth = lineWidth
        ctx.arc(
          props.width,
          props.width,
          props.width / 1.15,
          Math.PI * 1.5,
          radian + 1.5 * Math.PI,
          false
        )
        ctx.strokeStyle = props.color
        ctx.stroke()

        ctx.closePath()
      }
    }

    onMounted(() => drawSchedule())

    return () => (
      <div class="canvas-container">
        <div class="canvas-container" style={{
          position: 'relative',
          width: `${props.width}px`,
          height: `${props.height}px`
        }}>
          <canvas
            ref={canvasElement}
            style={{
              width: `${props.width}px`,
              height: `${props.height}px`,
              transform: 'rotate(-110deg)'
            }}
          />
          {props.text ? <span style={{
            position: 'absolute',
            top: `${props.height / 2 - 13}px`,
            display: 'flex',
            justifyContent: 'center',
            width: `${props.width}px`,
            fontSize: '26px'
          }}>{props.text}</span> : null }
        </div>
      </div>
    )
  }
})


import { defineComponent, PropType } from '@vue/runtime-core'
import { ProjectStatistic } from '../types'
import { CanvasSchedule } from '@/UI/canvas-schedule'
import { computed } from 'vue'

export default defineComponent({
  props: {
    statistic: {
      type: Object as unknown as PropType<ProjectStatistic|null>,
      required: true
    }
  },
  setup (props) {
    const tmcPercent = computed(() => {
      return props.statistic?.countedTmc?.percent || 0
    })

    const zonesPercent = computed(() => {
      return props.statistic?.countedZones?.percent || 0
    })

    const tmsNumbersDocument = computed(() => {
      return props.statistic?.countedTmc?.scannedTmc || 0
    })

    return () => (
      <div class="layout">
        <p class="col-title">Ход инвентаризации</p>
        <div class="stat-col mt-3">
          <div class="row">
            <div class="col">
              <p style="text-align: center;">Просчитано ТМЦ</p>
              {props.statistic && props.statistic.countedTmc
                ? <><div class="modalWindow">
                  <span> Просканировано ТМЦ: {props.statistic.countedTmc.scannedTmc} из {props.statistic.countedTmc.allsTmc} </span>
                </div><CanvasSchedule
                  width={150}
                  height={150}
                  color={tmcPercent.value ? '#409bff' : '#f8f8ff'}
                  procent={tmcPercent.value}
                  text={`${tmsNumbersDocument.value}`}
                /></>
                : null
              }
            </div>
            <div class="col">
              <p style="text-align: center;">Просканировано зон</p>
              {props.statistic && props.statistic.countedZones
                ? <><div class="modalWindow">
                  <span> Просканировано зон: {props.statistic.countedZones?.readyZones} из {props.statistic.countedZones?.allZones} </span>
                </div><CanvasSchedule
                  width={150}
                  height={150}
                  color={zonesPercent.value ? '#8940ff' : '#f8f8ff'}
                  procent={zonesPercent.value}
                  text={`${zonesPercent.value}%`} /></>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
})

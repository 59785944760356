import { DefaultInput, FormErrors, ModalForm } from 'apptimizm-ui'
import { ISelectValue, Template, TemplateExportMeta, TemplateMeta } from '../types'
import { Ref, watch } from 'vue'
import { ButtonLoader } from '@/UI/loader'
import Loading from './loading'
import Unloading from './unloading'
import { mutations } from '@/store'

interface ModalProps {
  type: string
  templateForm: Ref<Template>
  formErrors: Ref<FormErrors>
  templateMeta: TemplateMeta
  templateExportMeta: TemplateExportMeta
  templateChoicesOptions: Ref<ISelectValue[]>
  isLoading: Ref<{ [code: string]: boolean }>
  isConfirm: boolean,
  confirmType: string,
  toggle: (show: boolean, type?: string) => void
  toggleConfirm: (type: string) => void
  selectTemplate: (v: Template|null) => void
  exportFile: () => void
  submit: () => Promise<boolean>
  deleteTemplate: () => void
  onDrop: (e: any, target: string) => void
  onStartDrag: (e: any, choice: ISelectValue) => void
  replaceOnDblcClick: (item: ISelectValue, target: 'selected'|'options') => void
  unusedParams: Ref<ISelectValue[]>
  selecteUnusedParam: (param: ISelectValue) => void
  templateId: string|null
}

export const Modal = (
  {
    type,
    templateForm,
    formErrors,
    templateMeta,
    templateExportMeta,
    templateChoicesOptions,
    isLoading,
    isConfirm,
    confirmType,
    toggle,
    toggleConfirm,
    selectTemplate,
    exportFile,
    submit,
    deleteTemplate,
    onDrop,
    onStartDrag,
    replaceOnDblcClick,
    unusedParams,
    selecteUnusedParam,
    templateId
  }: ModalProps): JSX.Element => {
  const submitAndCheck = async () => {
    const result = await submit()
    if (result) {
      toggle(false)
      mutations.pushNotification('Шаблон сохранён')
    }
  }

  watch(templateForm, () => {
    formErrors.value.data = {}
  }, { deep: true })

  const modalConfirm = () => {
    return (
      <ModalForm
        header={confirmType === 'create' ? 'Назовите шаблон' : 'Подтверждение'}
        cancel={() => toggleConfirm('')}
        confirm={() => {}}
        slots={{
          default: () => {
            return (
              <div class="mt-4">
                {
                  confirmType === 'create'
                    ? <label><DefaultInput
                      modelValue={templateForm.value.name} placeholder="Название шаблона"
                      class={templateForm.value.name ? 'input-placeholder-up' : ''}
                      onValueChange={(v: string) => { templateForm.value.name = v }}
                    /></label>
                    : <p>Вы действительно хотите удалить шаблон {templateForm.value.name}?</p>
                }
              </div>
            )
          },
          buttons: () => {
            return (
              <div class="mt-4">
                {confirmType === 'create'
                  ? <button class="primary-button" onClick={() => toggleConfirm('')}>Сохранить</button>
                  : <div class="row">
                    <button class="default-button" onClick={() => toggleConfirm('')}>
                      Нет, отменить</button>
                    <button onClick={() => { deleteTemplate(); mutations.pushNotification('Шаблон удалён') }} class="danger-button">
                      Да, удалить
                    </button>
                  </div>
                }
              </div>
            )
          }
        }}
      />
    )
  }

  return (
    <ModalForm
      header={type === 'loading' ? 'Загрузка шаблонов' : 'Выгрузка шаблонов'}
      cancel={() => toggle(false)}
      confirm={() => {}}
      slots={{
        default: () => {
          return (
            <div class="mt-4">
              <div class="modal-tab">
                <button
                  onClick={() => toggle(true, 'loading')}
                  class={type === 'loading' ? 'primary-button' : 'default-button'}
                >Загрузка
                </button>
                <button
                  disabled={!templateId}
                  onClick={() => toggle(true, 'unloading')}
                  class={`${type === 'unloading' ? 'primary-button' : 'default-button'} ${!templateId ? 'disableElement' : ''}`}
                >Выгрузка
                </button>
              </div>
              {type === 'loading'
                ? Loading({
                  templateForm,
                  formErrors,
                  templateMeta,
                  templateChoicesOptions,
                  toggleConfirm,
                  selectTemplate,
                  replaceOnDblcClick,
                  onStartDrag,
                  onDrop,
                  unusedParams,
                  selecteUnusedParam
                })
                : Unloading({
                  templateForm,
                  formErrors,
                  templateExportMeta,
                  templateChoicesOptions,
                  toggleConfirm,
                  selectTemplate,
                  replaceOnDblcClick,
                  onStartDrag,
                  onDrop
                })
              }
              {isConfirm ? modalConfirm() : null}
            </div>
          )
        },
        buttons: () => <div class="row row--right mt-2">
          <button
            disabled={!templateForm.value.id}
            class={!isLoading.value.exportFile ? 'button-export' : 'button-export load'}
            onClick={exportFile}>
            <ButtonLoader hidden={!isLoading.value.exportFile}/>
            Экспорт в файл
          </button>
          <button onClick={submitAndCheck} class={{ 'primary-button': true, error: !templateForm.value.fields.length }}>
            <div class="error-message">
              <p class="title">Внимание!</p>
              <span>Файл не содержит</span>
              <span>список полей!</span>
            </div>
            Сохранить
          </button>
        </div>
      }}
    />
  )
}

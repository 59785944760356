import { reactive } from 'vue'
import axios from '@/axios'
import { host } from '@/const'

export interface ISelectId { id: string, name: string }

export const storageName4ExporDocuments = reactive({
  storageNames: [{ id: 'Все', name: 'Все' }],

  selectedStorageName: { id: 'Все', name: 'Все' },

  async getStorageNames (project: string) {
    const responseData = (await axios.get(`${host}/api/v1/zone/storage_names/`, { params: { project } })).data
    this.storageNames = [this.storageNames[0], ...(responseData.storage_names as string[]).map((name) => ({ id: name, name }))]
  },

  getSelectedStorageName (id: string) {
    return this.storageNames.find((storageName) => storageName.id === id) || this.selectedStorageName
  }
})

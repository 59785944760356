
import { PropType, computed, defineComponent, ref } from 'vue'
import { ModalForm } from 'apptimizm-ui'
import Checkbox from '@/UI/checkbox'
import { ButtonLoader } from '@/UI/loader'
import { mutations } from '@/store'
import { getCeleryTask } from '@/core/celery'
import axios from '@/axios'
import { host } from '@/const'

export type TDeleteParams = 'delete_all' | 'delete_remainders'

export default defineComponent({
  props: {
    closeModal: {
      type: Function as PropType<() => void>,
      required: true
    },
    projectId: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup (props) {
    // Управление чекбоксами
    const params = ref<TDeleteParams[]>([])

    function toggleParams (addOrRemoveParam: TDeleteParams) {
      if (params.value.includes(addOrRemoveParam)) {
        params.value = params.value.filter((param) => param !== addOrRemoveParam)
        return
      }

      params.value.push(addOrRemoveParam)
    }
    //

    // Переход между шагами модалки
    const modalStep = ref(0)

    const isNextConfirm = computed(() => {
      return !!params.value.length
    })

    function goToConfirmStep () {
      if (!isNextConfirm.value) return
      modalStep.value += 1
    }

    const modalSteps = [{
      header: 'Удалить справочник',
      default () {
        return (<div class="mt-3">
          <Checkbox
            onClick={() => { toggleParams('delete_remainders') }}
            placeholder={'Удалить остатки'}
            modelValue={params.value.includes('delete_remainders')}
          />
          <Checkbox
            onClick={() => { toggleParams('delete_all') }}
            placeholder={'Удалить справочник'}
            modelValue={params.value.includes('delete_all')}
          />
        </div>)
      },
      bottom () {
        return (<button
          class="primary-button mt-3"
          onClick={goToConfirmStep}
          disabled={!isNextConfirm.value}
        >Подтвердить</button>)
      }
    }, {
      header: 'Удалить справочник',
      default () {
        return (<p class="mt-3">Вы действительно хотите удалить остатки или справочник?</p>)
      },
      bottom () {
        return (<div class="mt-3 row">
          <button class="default-button" onClick={props.closeModal}>Нет, отменить</button>
          <button onClick={deleteDirectory} class="primary-button">
            <ButtonLoader hidden={!isLoading.value}/>
            Да, удалить
          </button>
        </div>)
      }
    }]

    const selectedStep = computed(() => {
      return modalSteps[modalStep.value]
    })
    //

    // Удаление
    const isLoading = ref(false)

    async function deleteDirectory () {
      if (isLoading.value) return
      isLoading.value = true

      try {
        const response = (await axios.post(`${host}/api/v1/project/delete_products/`, {
          project: props.projectId,
          params: params.value
        })).data

        if (response.status_code === 400) {
          mutations.pushNotification(response.errors[0], true)
        } else {
          await getCeleryTask(response.result_id)
          mutations.pushNotification('Удаление произошло успешно')
        }
      } catch {
        mutations.pushNotification('Ошибка при удалении', true)
      } finally {
        props.closeModal()
        isLoading.value = false
      }
    }
    //

    return () => (<ModalForm
      header={selectedStep.value.header}
      cancel={props.closeModal}
      confirm={() => {}}
      slots={{
        default: selectedStep.value.default,
        buttons: selectedStep.value.bottom
      }}
    />)
  }
})

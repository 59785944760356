
import { defineComponent, onMounted, Ref, ref } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import {
  ProjectMeta,
  Project,
  RmmSettings,
  RmmSettingsMeta,
  TerminalSettings,
  TerminalSettingsMeta,
  ISelectValue
} from './types'
import axios from '@/axios'
import { DefaultInput, NumberInput, DefaultSelect, FormErrors, LineLoader } from 'apptimizm-ui'
import Checkbox from '@/UI/checkbox'
import { getCeleryTask } from '../../core/celery/index'
import { host } from '../../const'
import moment from 'moment'
import WorkingWithProject from './working-with-project/index.vue'
import InventoryProgress from './inventory-progress/index.vue'
import Statistic from './statistic/index.vue'
import { mutations } from '../../store/index'
import { ButtonLoader } from '../../UI/loader'
import { storageName4ExporDocuments } from './working-with-project/store/storageName4ExporDocuments'
import CheckPasswordModal from './check-password-modal/index.vue'

export default defineComponent({
  setup () {
    const projectMeta = new ProjectMeta()
    const project: Ref<Project> = ref(new Project()) as Ref<Project>
    const rmmSettings: Ref<RmmSettings> = ref(new RmmSettings())
    const rmmSettingsMeta = new RmmSettingsMeta()
    const { params, meta } = useRoute()
    const { push } = useRouter()
    const terminalSettings: Ref<TerminalSettings> = ref(new TerminalSettings())
    const terminalSettingsMeta = new TerminalSettingsMeta()
    const formErrors = ref<FormErrors>(new FormErrors({}))
    const isLoading = ref<{ [code: string]: boolean }>({
      loadFile: false,
      celeryHandler: false,
      main: false
    })

    onMounted(async () => {
      isLoading.value.main = true
      try {
        project.value = projectMeta.load((await axios.get(projectMeta.endpoint + params.id)).data)
        rmmSettings.value = rmmSettingsMeta.load((await axios.get(
          rmmSettingsMeta.endpoint + project.value.rmmSettings)
        ).data)
        terminalSettings.value = terminalSettingsMeta.load((await axios.get(
          terminalSettingsMeta.endpoint + project.value.terminalSettings)
        ).data)
        document.title = meta.title + project.value.name
        storageName4ExporDocuments.getStorageNames(project.value.id)
      } catch (e) { push('/projects') }
      isLoading.value.main = false
    })

    // Открытие настроек после подтверждения пароля
    const showSettings = ref(false)

    const isShowCheckPasswordModal = ref(false)

    function closeSettingsOrCheckPassword () {
      if (showSettings.value) {
        showSettings.value = false
        return
      }

      isShowCheckPasswordModal.value = true
    }
    //

    const submit = async () => {
      const rmmResponse = (await axios.put(
        rmmSettingsMeta.endpoint + rmmSettings.value.id,
        rmmSettingsMeta.dump(rmmSettings.value))
      ).data
      const terminalResponse = (await axios.put(
        terminalSettingsMeta.endpoint + terminalSettings.value.id,
        terminalSettingsMeta.dump(terminalSettings.value))
      ).data

      const projectData = projectMeta.dump(project.value)

      if (projectData.template) delete projectData.template
      if (projectData.auto_assign_enbale) delete projectData.auto_assign_enbale

      const projectResponse = (await axios.put(
        projectMeta.endpoint + params.id, projectData
      )).data

      if (rmmResponse?.errors) {
        formErrors.value = rmmSettingsMeta.errors(rmmResponse.errors)
        mutations.pushNotification('Ошибка сохранения Параметров РММ', true)
      } else mutations.pushNotification('Настройки Параметров РММ успешно сохранены')
      if (terminalResponse?.errors) {
        formErrors.value = terminalSettingsMeta.errors(terminalResponse.errors)
        mutations.pushNotification('Ошибка сохранения Параметров Терминала', true)
      } else mutations.pushNotification('Настройки Параметров Терминала успешно сохранены')
      if (projectResponse?.errors) {
        formErrors.value = projectMeta.errors(projectResponse.errors)
        mutations.pushNotification('Ошибка сохранения Параметров Учет без УК', true)
      } else mutations.pushNotification('Настройки Параметров Учет без УК успешно сохранены')
    }

    const updateTemplateInProject = async (templateId: string|null, type: 'template'|'templateExport') => {
      project.value[type] = templateId
      if (templateId !== null) {
        await axios.patch(projectMeta.endpoint + params.id, projectMeta.dump(project.value))
      }
    }

    const celeryHandler = async () => {
      isLoading.value.celeryHandler = true
      const response = (await axios.post(host + `/api/v1/project/${params.id}/export_settings/`)).data
      const celaryResult = await getCeleryTask(response.result_id)
      if (celaryResult) {
        window.open(celaryResult.data.result)
        mutations.pushNotification('Настройки проекта успешно выгружены')
      } else mutations.pushNotification('Ошибка выгрузки настроек проекта', true)
      isLoading.value.celeryHandler = false
    }

    const loadFile = async (files: FileList) => {
      isLoading.value.loadFile = true
      const formData = new FormData()
      formData.append('file', files[0])
      const result = (await axios.post(host + '/api/v1/file', formData)).data

      const response = (await axios.post(host + '/api/v1/project/import_settings/', {
        file: result.id,
        project: params.id
      })).data

      const celaryResult = await getCeleryTask(response.result_id)
      if (celaryResult && !celaryResult.data.result.errors.length) {
        rmmSettings.value = rmmSettingsMeta.load(
          (await axios.get(rmmSettingsMeta.endpoint + project.value.rmmSettings)
          ).data)

        terminalSettings.value = terminalSettingsMeta.load(
          (await axios.get(terminalSettingsMeta.endpoint + project.value.terminalSettings)
          ).data)
        mutations.pushNotification('Настройки проекта успешно загружены')
      } else mutations.pushNotification('Ошибка загружены настроек проекта', true)
      isLoading.value.loadFile = false
    }

    const issuingTaskList = [{ name: 'Наименее загруженному пользователю', value: 'least_loaded_user' }, { name: 'Текущему пользователю', value: 'current_user' }]
    const productNameList = [{ name: 'По коду товара', value: 'by_product_code' }, { name: 'По штрих-коду', value: 'by_barcode' }]
    const unknownBarcodeList = [{ name: 'Разрешать', value: 'allow' }, { name: 'Запрещать', value: 'disallow' }]
    const recalculationDiscrepancyList = [{ name: 'Скан.', value: 'scan' }, { name: 'УК.', value: 'controller' }]
    const checkDmList = [
      { name: 'Без проверки DM', value: 'without_dm_check' },
      { name: 'Только наличие DM', value: 'only_dm_check' },
      { name: 'Соответствие DM и ШК.', value: 'conformity_dm' },
      { name: 'Привязка DM к ШК', value: 'binding_dm' }
    ]
    const checkAmList = [{ name: 'Без проверки АМ', value: 'without_am_check' }, { name: 'Только наличие АМ', value: 'only_am_check' }, { name: 'Соответствие АМ и ШК.', value: 'conformity_am' }]

    return () => (
      <div style="min-width: 1450px;">
        <div class="page-top-panel small">
          <h1>{project.value.name}</h1>
          <p>{rmmSettings.value.extendedTasks}</p>
        </div>
        <div class='line-loader-container'>
          {isLoading.value.main && <LineLoader/>}
        </div>
        {!isLoading.value.main && <div class="layout settings">
          <div class="row">
            <div class="col">
              <p class="col-title col-title--small">Адрес</p>
              <p>{project.value.address}</p>
            </div>
            <div class="col">
              <p class="col-title col-title--small">Дата инвентаризации</p>
              <p>{moment(project.value.createdAt).locale('ru').format('D MMMM YYYY')}</p>
            </div>
            <div class="col">
              <p class="col-title col-title--small">Ответственный менеджер</p>
              <p>{project.value.manager.lastName} {project.value.manager.firstName} {project.value.manager.middleName}</p>
            </div>
          </div>
          <button onClick={closeSettingsOrCheckPassword} class="gear"/>
          {isShowCheckPasswordModal.value && <CheckPasswordModal
            rmmSettings={rmmSettings.value}
            closeModal={() => { isShowCheckPasswordModal.value = false }}
            confirmCallback={() => { showSettings.value = true }}
          />}
          <div class={showSettings.value ? 'settings-block' : 'settings-block hide'}>
            <div class="settings-item">
              <p class="col-title">Параметры РММ</p>
              <div class="row mt-3">
                <label>
                  <NumberInput
                    class={rmmSettings.value.autoZonesAmount >= 0 ? 'input-placeholder-up' : ''}
                    modelValue={rmmSettings.value.autoZonesAmount}
                    placeholder="Кол-во автоназначенных зон"
                    onValueChange={(v: number) => { rmmSettings.value.autoZonesAmount = v }}
                    errors={formErrors.value.forField('autoZonesAmount')}
                  />
                </label>
                <label>
                  <DefaultInput
                    class={rmmSettings.value.password ? 'input-placeholder-up' : ''}
                    placeholder="Пароль администратора"
                    modelValue={rmmSettings.value.password}
                    onValueChange={(v: string) => { rmmSettings.value.password = v }}
                    errors={formErrors.value.forField('password')}
                  />
                </label>
                <label>
                  <NumberInput
                    class={rmmSettings.value.norm >= 0 ? 'input-placeholder-up' : ''}
                    modelValue={rmmSettings.value.norm}
                    placeholder="Норма счётчика"
                    onValueChange={(v: number) => { rmmSettings.value.norm = v }}
                    errors={formErrors.value.forField('norm')}
                  />
                </label>
                <label>
                  <Checkbox
                    onClick={() => { rmmSettings.value.extendedTasks = !rmmSettings.value.extendedTasks }}
                    placeholder="Расширенный механизм заданий"
                    modelValue={!!rmmSettings.value.extendedTasks}
                  />
                  <Checkbox
                    onClick={() => { project.value.accountingWithoutYk = !project.value.accountingWithoutYk }}
                    placeholder="Учет без УК"
                    modelValue={!!project.value.accountingWithoutYk}
                  />
                </label>
              </div>
            </div>
            <div class="settings-item">
              <p class="col-title">Параметры терминала</p>
              <div class="row">
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.issuingTask}
                  idKey="value"
                  titleKey="name"
                  placeholder="Выдача задания для УК"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.issuingTask = { name: v.name, value: v.id } }}
                  items={issuingTaskList.map(v => ({ id: v.value, name: v.name }))}
                />
                <label>
                  <DefaultInput
                    class={terminalSettings.value.lengthBarcodePallets ? 'input-placeholder-up' : ''}
                    placeholder="Длина ШК паллеты"
                    modelValue={terminalSettings.value.lengthBarcodePallets}
                    onValueChange={(v: string) => { terminalSettings.value.lengthBarcodePallets = v }}
                    errors={formErrors.value.forField('lengthBarcodePallets')}
                  />
                </label>
                <label>
                  <DefaultInput
                    class={terminalSettings.value.complianceCodes ? 'input-placeholder-up' : ''}
                    placeholder="Коды соответствия ШК х5"
                    modelValue={terminalSettings.value.complianceCodes}
                    onValueChange={(v: string) => { terminalSettings.value.complianceCodes = v }}
                    errors={formErrors.value.forField('complianceCodes')}
                  />
                </label>
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.productName}
                  idKey="value"
                  titleKey="name"
                  placeholder="Наименование товара"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.productName = { value: v.id, name: v.name } }}
                  items={productNameList.map(v => ({ id: v.value, name: v.name }))}
                />
              </div>
              <div class="row">
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.unknownBarcode}
                  idKey="name"
                  titleKey="name"
                  placeholder="Неизвестный штрих-код"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.unknownBarcode = { name: v.name, value: v.id } }}
                  items={unknownBarcodeList.map(v => ({ id: v.value, name: v.name }))}
                />
                <label>
                  <DefaultInput
                    class={terminalSettings.value.trimmingBarcode ? 'input-placeholder-up' : ''}
                    placeholder="Обрезка ШК с начала и с конца"
                    modelValue={terminalSettings.value.trimmingBarcode}
                    onValueChange={(v: string) => { terminalSettings.value.trimmingBarcode = v }}
                    errors={formErrors.value.forField('trimming_barcode')}
                  />
                </label>
                <label>
                  <DefaultInput
                    class={terminalSettings.value.password ? 'input-placeholder-up' : ''}
                    placeholder="Пароль редактирования"
                    modelValue={terminalSettings.value.password}
                    onValueChange={(v: string) => { terminalSettings.value.password = v }}
                    errors={formErrors.value.forField('password')}
                    password={true}
                    disabled={true}
                  />
                </label>
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.recalculationDiscrepancy}
                  idKey="name"
                  titleKey="name"
                  placeholder="Пересчет при расхождении"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.recalculationDiscrepancy = { value: v.id, name: v.name } }}
                  items={recalculationDiscrepancyList.map(v => ({ id: v.value, name: v.name }))}
                />
              </div>
              <div class="row">
                <label>
                  <NumberInput
                    class={terminalSettings.value.suspiciousBarcodesAmount >= 0 ? 'input-placeholder-up' : ''}
                    placeholder="Подозрительное кол-во для ШК"
                    modelValue={terminalSettings.value.suspiciousBarcodesAmount}
                    onValueChange={(v: number) => { terminalSettings.value.suspiciousBarcodesAmount = v }}
                    errors={formErrors.value.forField('suspiciousBarcodesAmount')}
                  />
                </label>
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.checkDm}
                  idKey="value"
                  titleKey="name"
                  placeholder="Проверка DM"
                  onValueChange={(v: { id: string, name: string }) => { terminalSettings.value.checkDm = { name: v.name, value: v.id } }}
                  items={checkDmList.map(v => ({ id: v.value, name: v.name }))}
                />
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={terminalSettings.value.checkAm}
                  idKey="value"
                  titleKey="name"
                  placeholder="Проверка АМ"
                  onValueChange={(v: {id: string, name: string}) => { terminalSettings.value.checkAm = { name: v.name, value: v.id } }}
                  items={checkAmList.map(v => ({ id: v.value, name: v.name }))}
                />
                <div>
                  <Checkbox
                    onClick={() => { terminalSettings.value.errorCorrection = !terminalSettings.value.errorCorrection }}
                    placeholder="Исправление ошибок"
                    modelValue={!!terminalSettings.value.errorCorrection}
                  />
                  <Checkbox
                    onClick={() => { terminalSettings.value.searchByProductCode = !terminalSettings.value.searchByProductCode }}
                    placeholder="Поиск по коду товара"
                    modelValue={!!terminalSettings.value.searchByProductCode}
                  />
                  <Checkbox
                    onClick={() => { terminalSettings.value.manualInputQuantity = !terminalSettings.value.manualInputQuantity }}
                    placeholder="Ручной ввод кол-ва"
                    modelValue={!!terminalSettings.value.manualInputQuantity}
                  />
                  <Checkbox
                    onClick={() => { terminalSettings.value.isScanningOfWeightProduct = !terminalSettings.value.isScanningOfWeightProduct }}
                    placeholder="Весовой товар"
                    modelValue={!!terminalSettings.value.isScanningOfWeightProduct}
                  />
                </div>
              </div>
              <div class="row right">
                <DefaultSelect
                  class="select-placeholder-offset"
                  modelValue={{}}
                  placeholder="IP сервера"
                  onValueChange={(v: string) => v}
                  items={[]}
                />
                <button onClick={() => celeryHandler()} class={!isLoading.value.celeryHandler ? 'button-export' : 'button-export load'}>
                  <ButtonLoader hidden={!isLoading.value.celeryHandler}/>
                  Экспорт настроек в файл
                </button>
                <button class={!isLoading.value.loadFile ? 'button-save' : 'button-save load'}>
                  <ButtonLoader hidden={!isLoading.value.loadFile}/>
                  <input class="hidden" type="file" onChange={(e: Event) => loadFile((e.target as HTMLFormElement).files)}/>
                  Загрузить настройки проекта
                </button>
                <button class="primary-button" onClick={submit}>Сохранить</button>
              </div>
            </div>
          </div>
        </div>}
        {!isLoading.value.main && <div class="row statistic mt-3">
          <Statistic
            statistic={project.value.statistic}
          />
          <InventoryProgress
            statistic={project.value.statistic}
          />
          <WorkingWithProject
            updateTemplateInProject={updateTemplateInProject}
            projectId={params.id as string}
            templateId={project.value.template}
            templateExportId={project.value.templateExport}
          />
        </div>}
      </div>
    )
  }
})

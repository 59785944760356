import Checkbox from '@/UI/checkbox'
import { DefaultInput, DefaultSelect, FormErrors, NumberInput, RelationSelect } from 'apptimizm-ui'
import axios from '@/axios'
import { Ref } from 'vue'
import { ISelectValue, Template, TemplateExportMeta } from '../types'
import { ISelectId, storageName4ExporDocuments } from './store/storageName4ExporDocuments'

interface IProps {
  templateForm: Ref<Template>
  formErrors: Ref<FormErrors>
  templateExportMeta: TemplateExportMeta
  templateChoicesOptions: Ref<ISelectValue[]>
  toggleConfirm: (type: string) => void
  selectTemplate: (v: Template|null) => void
  onDrop: (e: any, target: string) => void
  onStartDrag: (e: any, choice: ISelectValue) => void
  replaceOnDblcClick: (item: ISelectValue, target: 'selected'|'options') => void
}

export default ({
  templateForm,
  formErrors,
  templateExportMeta,
  templateChoicesOptions,
  toggleConfirm,
  selectTemplate,
  replaceOnDblcClick,
  onStartDrag,
  onDrop
}: IProps) => (
  <>
    <div class="row row--70-30 mt-4">
      <div>
        <RelationSelect
          class="select-placeholder-offset"
          axios={axios}
          placeholder="Шаблон по умолчанию"
          constantPlaceholder={false}
          modelValue={templateForm.value}
          endpoint={templateExportMeta.endpoint}
          itemConverter={(v: any) => templateExportMeta.load(v)}
          onValueChange={(v: any) => selectTemplate(v)}
          responseItemsKey="results"
          responseTotalKey="count"
          clearable={true}
        />
      </div>
      {templateForm.value.name
        ? <button class="default-button" onClick={() => toggleConfirm('delete')}>Удалить</button>
        : <button class="default-button" onClick={() => toggleConfirm('create')}>Новый</button>
      }
    </div>
    <div class="row mt-2">
      <label>
        <DefaultInput
          class={templateForm.value.fieldSeparator ? 'input-placeholder-up' : ''}
          modelValue={templateForm.value.fieldSeparator} placeholder="Разделитель полей"
          errors={formErrors.value.forField('fieldSeparator')}
          onValueChange={(v: string) => { templateForm.value.fieldSeparator = v }}
        />
      </label>
      <label>
        <DefaultInput
          class={templateForm.value.decimalSeparator ? 'input-placeholder-up' : ''}
          modelValue={templateForm.value.decimalSeparator} placeholder="Десятичный разделитель"
          errors={formErrors.value.forField('decimalSeparator')}
          onValueChange={(v: string) => { templateForm.value.decimalSeparator = v }}
        />
      </label>
    </div>
    <div class="choices-block mt-2">
      <div class="choice-header row--50-50">
        <div class="title">Список полей</div>
        <div class="title">Поля в файле</div>
      </div>
      <div
        class="choices-body row--50-50"
        onDragover={(e) => e.preventDefault()}
      >
        <ul class="choice-list" onDrop={(e: Event) => onDrop(e.target, 'options')}>
          {templateChoicesOptions.value && templateChoicesOptions.value.map((choice: ISelectValue) =>
            <li onDblclick={() => replaceOnDblcClick(choice, 'selected')} draggable={true} onDragstart={(e) => onStartDrag(e, choice)} class="choice-item">
              {choice.name}
            </li>
          )}
        </ul>
        <ul class="choice-list" onDrop={(e: Event) => onDrop(e.target, 'selected')}>
          {templateForm.value.fields && templateForm.value.fields.map((choice: ISelectValue) =>
            <li onDblclick={() => replaceOnDblcClick(choice, 'options')} draggable={true} onDragstart={(e) => onStartDrag(e, choice)} class="choice-item">
              {choice.name}
            </li>
          )}
        </ul>
      </div>
    </div>
    <div class="template-loading">
      <div class="template-loading-grid">
        <NumberInput
          class={`input-zones ${!templateForm.value.zoneNumberStart ? 'hide-zero' : 'input-placeholder-up'}`}
          placeholder="Зоны с..."
          modelValue={templateForm.value.zoneNumberStart}
          onValueChange={(v: number) => { templateForm.value.zoneNumberStart = parseInt(String(v)) } }
        />
        <NumberInput
          class={`input-zones ${!templateForm.value.zoneNumberEnd ? 'hide-zero' : 'input-placeholder-up'}`}
          placeholder="Зоны по..."
          modelValue={templateForm.value.zoneNumberEnd}
          onValueChange={(v: number) => { templateForm.value.zoneNumberEnd = parseInt(String(v)) } }
        />
      </div>
      <div class="template-loading-grid">
        <DefaultSelect
          class="select-placeholder-offset"
          modelValue={storageName4ExporDocuments.getSelectedStorageName(templateForm.value.storageName)}
          placeholder="Код склада"
          onValueChange={(v: ISelectId) => { templateForm.value.storageName = v.id }}
          items={storageName4ExporDocuments.storageNames}
        />
      </div>
      <div class="template-loading-flex-column">
        <Checkbox
          onClick={() => { templateForm.value.singleExport = !templateForm.value.singleExport }}
          placeholder="Поединичная выгрузка счётчика"
          modelValue={templateForm.value.singleExport}
        />

        <Checkbox
          onClick={() => { templateForm.value.isProductsFindByCode = !templateForm.value.isProductsFindByCode }}
          placeholder="Товары найденные по коду"
          modelValue={templateForm.value.isProductsFindByCode}
        />

        <Checkbox
          onClick={() => { templateForm.value.isProductsFindByQrCode = !templateForm.value.isProductsFindByQrCode }}
          placeholder="Товары найденные по QR-коду"
          modelValue={templateForm.value.isProductsFindByQrCode}
        />
      </div>
    </div>
  </>
)

import { defineComponent, PropType } from 'vue'
import { ProjectStatistic } from '../types'
import moment from 'moment'

export default defineComponent({
  props: {
    statistic: {
      type: Object as unknown as PropType<ProjectStatistic|null>,
      required: true
    }
  },
  setup (props) {
    return () => (
      <div class="layout">
        <p class="col-title">Статистика проекта</p>
        <ul class="stat-col mt-3">
          <li class="row mt-2"><span>Количество товаров</span><span>{props.statistic?.productCount}</span></li>
          <li class="row mt-2"><span>Количество штрих-кодов</span><span>{props.statistic?.barcodeCount}</span></li>
          <li class="row mt-2"><span>Количество зон</span><span>{props.statistic?.zoneCount}</span></li>
          <li class="row mt-2"><span>Остатки по учету</span><span>{props.statistic?.accountBalance}</span></li>

          <li class="row mt-2">
            <span>Начало сканирования</span>
            {props.statistic?.scanBegin
              ? <span>{moment(props.statistic?.scanBegin).locale('ru').format('DD MMMM')}</span>
              : <span>нет даты</span>
            }
          </li>

          <li class="row mt-2">
            <span>Окончание сканирования</span>
            {props.statistic?.scanEnd
              ? <span>{moment(props.statistic?.scanEnd).locale('ru').format('DD MMMM')}</span>
              : <span>нет даты</span>
            }
          </li>
        </ul>
      </div>
    )
  }
})
